<template>
  <div class="container-fluid">
    <b-card>
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            :title="'views.playground.steps.step-1.informations.activities.title'"
            :title-class="'topnav-title-red-light'"
        />
      </div>
      <d-table
          :tablefields="tableFields"
          :items="selectedBlockPrices"
          :per-page="itemsPerPage"
          :totalRows="totalRows"
          :is-busy="isBusy"
          :current-page="currentPage"
          @current-page:update="onCurrentPageUpdate"
          @on:pagination-model:update="onPaginationModelChange"
          @on:radio-button-check="onRowCheck"
      >
      </d-table>
    </b-card>
  </div>
</template>
<script>

export default {
  props: {
    isPlaygroundPrices: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    itemsPerPage: 10,
    currentPage: 1,
    pagination: 1,
    isBusy: false,
  }),
  computed: {
    selectedActivities() {
      return this.playground.activities.length > 0;
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    alignRadioBtnToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left w-5';
    },
    selectedBlockPrices () {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    totalRows () {
      return this.$store.getters["playgrounds/getBlockPricesTotalItems"];
    },
    tableFields() {
      return [
        { key: "singleSelection", class: this.alignRadioBtnToLeft, label: '', sortable: false},
        {
          key: "name",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.wording"),
          sortable: false
        },
        {
          key: "activity",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.activity"),
          sortable: false
        },
        {
          key: "maxParticipantsCountLimit",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.steps.step-1.informations.activities.table.nb-players"),
          sortable: false
        },
        {
          key: "nicheDuration",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.duration"),
          sortable: false
        },
        {
          key: "pricePerParticipant",
          class: this.alignCellsToLeft,
          label: this.$t("views.playground.steps.step-1.informations.activities.table.public-price"),
          sortable: false
        },
        {
          key: "otherPrices",
          label: this.$t("views.playground.steps.step-1.informations.activities.table.other-prices"),
          sortable: false
        },
      ];
    }
  },
  methods: {
    onPaginationModelChange(pagination) {
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
    },
    onRowCheck(row) {
      this.$emit('on:block-price:selected', row.id);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
.card .card-body{
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
}

.playgraound-general-title {
  text-align: left;
  font: normal normal 900 22px Avenir;
  letter-spacing: 0px;
  color: #FF0101;
  opacity: 1;
}

.playground-generals-inf-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>
